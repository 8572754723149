/** @jsx jsx */
import { jsx } from "theme-ui"
import Link from "./Link"
export const BorderedLink = ({ children, ...props }) => (
  <Link
    sx={{
      border: `3px solid currentColor`,
      height: [50, , , , 60],
      py: 3,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      maxWidth: "100%",
      textAlign: "center",
      mb: 3,
      px: 2,
      bg: `rgba(255,255,255,0.7)`,
      backdropFilter: `blur(3px)`,
    }}
    {...props}
  >
    {children}
  </Link>
)

export default BorderedLink
