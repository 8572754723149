import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      width={1237}
      height={691}
      viewBox="0 0 1237 691"
      fill="none"
      {...props}
    >
      <circle
        cx={619.5}
        cy={459.5}
        r={230.5}
        stroke="#fff"
        strokeOpacity={0.5}
        strokeWidth={2}
      />
      <circle
        cx={619.5}
        cy={459.5}
        r={223.5}
        stroke="#fff"
        strokeOpacity={0.5}
        strokeWidth={2}
      />
      <circle
        r={95}
        transform="matrix(1 0 0 -1 150 96)"
        stroke="#fff"
        strokeOpacity={0.5}
        strokeWidth={2}
      />
      <circle
        r={89}
        transform="matrix(1 0 0 -1 150 96)"
        stroke="#fff"
        strokeOpacity={0.5}
        strokeWidth={2}
      />
      <path
        stroke="#fff"
        strokeOpacity={0.5}
        strokeWidth={2}
        d="M224.61 172.208l200 154"
      />
      <circle cx={221.5} cy={168.5} r={4.5} fill="#fff" fillOpacity={0.5} />
      <path
        stroke="#fff"
        strokeOpacity={0.5}
        strokeWidth={2}
        d="M109 48h84v95h-84z"
      />
      <path
        stroke="#fff"
        strokeOpacity={0.5}
        strokeWidth={2}
        d="M166 88h18v45h-18zM116 55h68v23h-68zM142 88h18v18h-18zM116 88h18v18h-18zM142 115h18v18h-18zM116 115h18v18h-18z"
      />
      <circle
        r={69}
        transform="matrix(1 0 0 -1 1167 273)"
        stroke="#fff"
        strokeOpacity={0.5}
        strokeWidth={2}
      />
      <circle
        r={61}
        transform="matrix(1 0 0 -1 1167 273)"
        stroke="#fff"
        strokeOpacity={0.5}
        strokeWidth={2}
      />
      <path
        stroke="#fff"
        strokeOpacity={0.5}
        d="M1142.42 273.16h15.103v23.887h-15.103zM1158.52 258.521h15.103v38.526h-15.103zM1174.62 242.417h15.103v54.629h-15.103z"
      />
      <path
        stroke="#fff"
        strokeOpacity={0.5}
        strokeWidth={2}
        d="M1097.35 304.939l-253.005 93"
      />
      <circle cx={1099.5} cy={304.5} r={4.5} fill="#fff" fillOpacity={0.5} />
      <circle
        cx={28.5}
        cy={456.5}
        r={27.5}
        stroke="#fff"
        strokeOpacity={0.5}
        strokeWidth={2}
      />
      <circle
        cx={28.5}
        cy={456.5}
        r={24.5}
        stroke="#fff"
        strokeOpacity={0.5}
        strokeWidth={2}
      />
      <path
        d="M42.707 468.707a1 1 0 000-1.414l-6.364-6.364a1 1 0 10-1.414 1.414L40.586 468l-5.657 5.657a1 1 0 101.414 1.414l6.364-6.364zM18 468h-1v1h1v-1zm.707-24.707a1 1 0 00-1.414 0l-6.364 6.364a1 1 0 101.414 1.414L18 445.414l5.657 5.657a1 1 0 101.414-1.414l-6.364-6.364zM42 467H18v2h24v-2zm-23 1v-24h-2v24h2z"
        fill="#fff"
        fillOpacity={0.5}
      />
      <path
        stroke="#fff"
        strokeOpacity={0.5}
        strokeDasharray="1 1"
        d="M18.5 459.5h8v8h-8z"
      />
      <path
        stroke="#fff"
        strokeOpacity={0.5}
        strokeWidth={2}
        d="M65.984 454l320-5"
      />
      <circle cx={61.5} cy={454.5} r={4.5} fill="#fff" fillOpacity={0.5} />
      <circle
        r={47.5}
        transform="matrix(1 0 0 -1 980.5 188.5)"
        stroke="#fff"
        strokeOpacity={0.5}
        strokeWidth={2}
      />
      <circle
        r={42.5}
        transform="matrix(1 0 0 -1 980.5 188.5)"
        stroke="#fff"
        strokeOpacity={0.5}
        strokeWidth={2}
      />
      <path
        stroke="#fff"
        strokeOpacity={0.5}
        d="M960.114 178.823h30.102v30.102h-30.102z"
      />
      <path
        d="M969.363 168.575H1000v31.102M969.717 168.928l-9.749 9.749"
        stroke="#fff"
        strokeOpacity={0.5}
      />
      <path
        stroke="#fff"
        strokeOpacity={0.5}
        strokeDasharray="2 2"
        d="M970.182 199.566l-9.749 9.748M970.328 168.575v31.102M1000 199.712h-30.174"
      />
      <path
        stroke="#fff"
        strokeOpacity={0.5}
        d="M1000.35 168.928l-9.745 9.749M1000.35 199.566l-9.745 9.748"
      />
      <path
        stroke="#fff"
        strokeOpacity={0.5}
        strokeWidth={2}
        d="M941.637 229.771l-127 105"
      />
      <circle cx={944.5} cy={227.5} r={4.5} fill="#fff" fillOpacity={0.5} />
      <circle
        r={25}
        transform="matrix(1 0 0 -1 1016 588)"
        stroke="#fff"
        strokeOpacity={0.5}
        strokeWidth={2}
      />
      <circle
        r={21}
        transform="matrix(1 0 0 -1 1016 588)"
        stroke="#fff"
        strokeOpacity={0.5}
        strokeWidth={2}
      />
      <path
        d="M1015.05 589.422L1009 579h12.1v3.84m-6.07 5.584l-6.03 10.408 11.99-.036v-3.79M981.672 581.945l-144-50"
        stroke="#fff"
        strokeOpacity={0.5}
        strokeWidth={2}
      />
      <circle cx={985.5} cy={583.5} r={4.5} fill="#fff" fillOpacity={0.5} />
    </svg>
  )
}

export default SvgComponent
