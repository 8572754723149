// import React from "react"
/** @jsx jsx */
import { jsx } from "theme-ui"
import { useStaticQuery, graphql } from "gatsby"

import { Container, Row, Col } from "./Grid"
import Badge from "../images/Certification/badgeIcon.svg"
import { H3, H4, P1 } from "./Typography"
// import LinkCTA from "./LinkCTA"

const Certification = ({ ...props }) => {
  const data = useStaticQuery(graphql`
    query {
      image1: file(relativePath: { eq: "Certification/Certification.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 85) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Container fluid {...props} sx={{ px: 0 }}>
      <Row sx={{ mx: 0 }}>
        <Col
          sx={{
            width: ["100%", , , "50%"],
            px: [0],
            backgroundImage: `url(${data.image1.childImageSharp.fluid.src})`,
            backgroundPosition: `center center`,
            backgroundSize: "cover",
            minHeight: ["auto", , , 773],
            pb: [(773 / 946) * 100 + "%", , , 0],
          }}
        />
        <Col
          sx={{
            width: ["100%", , , "50%"],
            bg: "blue",
            color: "white",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <div sx={{ pl: [4, , , 5], pr: 4 }}>
            <img src={Badge} alt="" />
            <H3 sx={{ mb: 4 }}>
              {`CELEBRATING ACHIEVEMENT`}
              <br></br>
              <H4 as="span">{`THROUGH DIGITAL CERTIFICATION`}</H4>
            </H3>

            <P1
              sx={{ width: ["100%", , , , "80%"] }}
            >{`After successfully completing the summative assessment, students receive a custom digital certificate of achievement that they can share with their educators, potential employers, or online on their social and professional websites.`}</P1>
          </div>
        </Col>
      </Row>
      {/* <Row sx={{ bg: "greyLight", textAlign: "center", py: 5, mx: 0 }}>
        <Col>
          <H4>{`Try it out`}</H4>
          <P1>{`The demo will provide you with temporary access to a short diagnostic assessment, a sample of the upgrading lessons, and a short summative assessment.`}</P1>
          <LinkCTA link={`/`} linkText={`VIEW DEMO`} />
        </Col>
      </Row> */}
    </Container>
  )
}

export default Certification
