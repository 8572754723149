/** @jsx jsx */
import { jsx } from "theme-ui"

function AdditionLarge(props) {
  return (
    <svg width={60} height={60} viewBox="0 0 60 60" fill="none" {...props}>
      <path
        stroke="#fff"
        strokeWidth={6}
        strokeLinecap="square"
        strokeLinejoin="round"
        d="M57 29H3M31 57V3"
      />
    </svg>
  )
}

function AdditionSmall(props) {
  return (
    <svg width={36} height={36} viewBox="0 0 36 36" fill="none" {...props}>
      <path
        stroke="#fff"
        strokeWidth={6}
        strokeLinecap="square"
        strokeLinejoin="round"
        d="M17.398 33V3M3 17.4h30"
      />
    </svg>
  )
}

function Division(props) {
  return (
    <svg width={28} height={29} viewBox="0 0 28 29" fill="none" {...props}>
      <path
        stroke="#fff"
        strokeWidth={6}
        strokeLinecap="square"
        strokeLinejoin="round"
        d="M25 15H3"
      />
      <circle cx={13.5} cy={3.5} r={3.5} fill="#fff" />
      <circle cx={13.5} cy={25.5} r={3.5} fill="#fff" />
    </svg>
  )
}

function Multiply(props) {
  return (
    <svg width={51} height={51} viewBox="0 0 51 51" fill="none" {...props}>
      <path
        stroke="#fff"
        strokeWidth={6}
        strokeLinecap="square"
        strokeLinejoin="round"
        d="M35.638 36.487L14.425 15.274M14.425 35.638l21.214-21.213"
      />
    </svg>
  )
}

function Subtraction(props) {
  return (
    <svg width={28} height={6} viewBox="0 0 28 6" fill="none" {...props}>
      <path
        stroke="#fff"
        strokeWidth={6}
        strokeLinecap="square"
        strokeLinejoin="round"
        d="M25 3H3"
      />
    </svg>
  )
}

export { AdditionLarge, AdditionSmall, Division, Multiply, Subtraction }
