/** @jsx jsx */
import { jsx } from "theme-ui"

function SvgComponent({ stroke = "#EFCB10", small = false, ...props }) {
  return (
    <svg
      width={small ? 492 / 2 : 492}
      height={small ? 522 / 2 : 522}
      viewBox="0 0 492 522"
      fill="none"
      {...props}
    >
      <path
        sx={{ stroke: stroke }}
        strokeWidth={8}
        strokeLinecap="square"
        strokeLinejoin="round"
        strokeDasharray="24 48"
        d="M35 516.343L486.343 65M30 371.343L358.343 43M6 473.343L473.343 6"
      />
    </svg>
  )
}

export default SvgComponent
