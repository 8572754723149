import React from "react"
/** @jsx jsx */
import { jsx } from "theme-ui"

import Link from "./Link"

import { P1, P2 } from "./Typography"
import ButtonClean from "./ButtonClean"
import NavbarLogo from "./NavbarLogo"
import { ButtonSolid } from "./ButtonSolid"
// import { InputText } from "./InputText"
import axios from "axios"
// import { useForm, Controller } from "react-hook-form"
import Input from "@material-ui/core/Input"
import InputLabel from "@material-ui/core/InputLabel"
import FormControl from "@material-ui/core/FormControl"
import { StylesProvider } from "@material-ui/core/styles"
import InputAdornment from "@material-ui/core/InputAdornment"
import IconButton from "@material-ui/core/IconButton"
import { MdVisibility, MdVisibilityOff } from "react-icons/md"

const FormSwitchButton = ({ ...props }) => {
  return (
    <ButtonClean
      type="button"
      sx={{
        fontFamily: "inherit",
        fontSize: "inherit",
        color: "teal",
        fontWeight: "inherit",
      }}
      {...props}
    />
  )
}

const InputField = ({ ...props }) => {
  return (
    <Input
      sx={{
        width: "100%",
        mb: 3,
        "&:before, &:after, &:hover:not(.Mui-disabled):before": {
          borderBottomWidth: "3px",
        },
      }}
      {...props}
    />
  )
}

const LoginForm = ({ type, typeSwitcher, ...props }) => {
  const [username, setUserName] = React.useState("")
  const [password, setPassword] = React.useState("")
  const [passwordVisible, setPasswordVisible] = React.useState(false)
  const [loginStatus, setLoginStatus] = React.useState(() => null)

  const collegelinkLoc = "https://secure.vretta.com/collegelink/"

  const attemptLogin = () => {
    setLoginStatus(() => null)
    axios
      .get(collegelinkLoc + "php/api/get-authenticate-user.php", {
        params: { username, password },
      })
      .then(res => {
        setLoginStatus(() => (
          <P2 sx={{ color: "teal" }}>{`Sending details.`}</P2>
        ))
        if (res.data.success) {
          // eslint-disable-next-line
          console.log("Success!:", res)

          window.location = `${collegelinkLoc}#/course-resource/user/${res.data.data.user_id}/${res.data.data.session_key}/collegelink/${username}`
        } else {
          // eslint-disable-next-line
          console.log("Fail:", res)
          if (
            res.data.errorDetail2.errorType == "USER_DOES_NOT_EXIST" ||
            res.data.errorDetail2.errorType == "USER_LOGIN_FAILURE" ||
            res.data.errorDetail2.errorType == "USER_LOGIN_BAD_PASS"
          ) {
            setLoginStatus(() => (
              <P2 sx={{ color: "red" }}>{`Incorrect username or password`}</P2>
            ))
          } else {
            setLoginStatus(() => (
              <P2
                sx={{ color: "red" }}
              >{`System Error (response undefined). Please contact support.`}</P2>
            ))
          }
        }
        return true
      })
      .catch(err => {
        setLoginStatus(() => (
          <P2>
            <span>{`Network Error. Please `}</span>
            <FormSwitchButton onClick={() => window.location.reload(true)}>
              click here
            </FormSwitchButton>
            <span>{` and try logging in again. If you continue to experience issues, please contact support.`}</span>
          </P2>
        ))
        axios
          .post("https://secure.vretta.com/lib/contact/page-error.php", {
            params: {
              studentid: username,
              systemMessage: err.responseText,
            },
          })
          .then(res => {
            // eslint-disable-next-line
            console.log("Success:", res)
            return true
          })
          .catch(err => {
            // eslint-disable-next-line
            return console.error("Error:", err)
          })
        // eslint-disable-next-line
        return console.error("Error:", err)
      })
  }

  const handleSubmit = e => {
    e.preventDefault()
    attemptLogin()
  }

  return (
    <StylesProvider injectFirst>
      <form
        onSubmit={e => handleSubmit(e)}
        sx={{ textAlign: "center" }}
        {...props}
      >
        <NavbarLogo sx={{ mb: 5, mx: "auto" }} />

        {type === "login" ? (
          <div
            sx={{
              display: "flex",
              flexDirection: "column",
              px: 4,
            }}
          >
            {loginStatus}
            <FormControl>
              <InputLabel htmlFor="usernameField">Username</InputLabel>
              <InputField
                id="usernameField"
                name="username"
                value={username}
                onChange={e => setUserName(e.target.value)}
                required
              />
            </FormControl>
            <FormControl>
              <InputLabel htmlFor="passField">Password</InputLabel>
              <InputField
                id="passField"
                name="password"
                onChange={e => setPassword(e.target.value)}
                value={password}
                type={passwordVisible ? "text" : "password"}
                required
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setPasswordVisible(!passwordVisible)}
                    >
                      {passwordVisible ? <MdVisibility /> : <MdVisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
            <ButtonSolid type="submit">LOGIN</ButtonSolid>
            <Link
              to="https://secure.vretta.com/collegelink/#/user/forgot-password"
              sx={{ color: "grey" }}
            >
              <P2 sx={{ fontWeight: "bold", mb: 0 }}>Forgot password?</P2>
            </Link>
          </div>
        ) : (
          <div
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              px: 4,
            }}
          >
            <P1 sx={{ color: "blue", mb: 3 }}>REGISTER</P1>
            <ButtonSolid
              to="https://secure.vretta.com/collegelink/#/course-registration/new-student/int"
              sx={{ width: "100%" }}
            >
              I AM A STUDENT
            </ButtonSolid>
            <ButtonSolid
              to="https://secure.vretta.com/teacher-registration.html"
              sx={{ width: "100%" }}
            >
              I AM AN EDUCATOR
            </ButtonSolid>
          </div>
        )}
        <div
          sx={{
            mt: 5,
            borderTop: "3px solid",
            borderTopColor: "blackAlt",
            pt: 3,
            width: "100%",
            fontWeight: "bold",
          }}
        >
          {type === "login" ? (
            <P2 sx={{ mb: 0, fontWeight: "bold" }}>
              New User?{" "}
              <FormSwitchButton onClick={() => typeSwitcher("signup")}>
                SIGN UP HERE
              </FormSwitchButton>
            </P2>
          ) : (
            <P2 sx={{ mb: 0, fontWeight: "bold" }}>
              Already have an account?{" "}
              <FormSwitchButton onClick={() => typeSwitcher("login")}>
                LOG IN HERE
              </FormSwitchButton>
            </P2>
          )}
        </div>
      </form>
    </StylesProvider>
  )
}

export { LoginForm }
