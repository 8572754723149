import React from "react"
/** @jsx jsx */
import { jsx } from "theme-ui"
import useOnClickOutside from "use-onclickoutside"

import { P1, P2 } from "./Typography"
import Link from "./Link"
import BorderedLink from "./BorderedLink"
import ButtonClean from "./ButtonClean"
import SocialLinks from "./SocialLinks"
import Dialog from "./Dialog"
import NavbarLogo from "./NavbarLogo"
import { LoginForm } from "./Forms"
import { MdClose } from "react-icons/md"

const MarketButton = props => (
  <BorderedLink
    sx={{
      mb: 0,
      px: 4,
      transition: "0.2s ease-in-out color",
      position: "relative",
      borderColor: "teal",
      color: "teal",
      cursor: "pointer",

      "&:before": {
        content: "''",
        height: "100%",
        width: "100%",
        bg: "teal",
        left: 0,
        transform: `scaleX(0)`,
        transformOrigin: "0",
        zIndex: 1,
        position: "absolute",
        transition: "0.2s ease-in-out transform",
      },

      "&:hover": {
        color: "white",
        "&:before": {
          transform: `scaleX(1)`,
        },
      },
    }}
    {...props}
  >
    <P2 sx={{ mb: `0`, position: "relative", zIndex: 3, fontWeight: "bold" }}>
      SIGNUP
    </P2>
  </BorderedLink>
)

const NavLink = ({ children, ...props }) => (
  <ButtonClean
    sx={{
      px: 4,
      mb: [4, , , 0],
      height: ["auto", , , "100%"],
      boxSizing: "border-box",
    }}
    {...props}
  >
    <P2 sx={{ mb: 0, textTransform: "uppercase" }}>{children}</P2>
  </ButtonClean>
)

export default function Navbar({ links, ...props }) {
  const [mobileNavOpen, setMobileNavOpen] = React.useState(false)
  const [scrolled, setScrolled] = React.useState(false)

  const handleScrollTo = ref => {
    setMobileNavOpen(false)
    ref.current.scrollIntoView({ behavior: "smooth" })
  }
  const handleWindowScroll = () => {
    setScrolled(window.pageYOffset > 0 ? true : false)
  }
  React.useEffect(() => {
    window.addEventListener("scroll", handleWindowScroll)
    return () => window.removeEventListener("scroll", handleWindowScroll)
  })

  // to close the mobile menu when clicking outside it
  const mobileMenuRef = React.useRef()
  useOnClickOutside(mobileMenuRef, () => setMobileNavOpen(false))

  const [modalType, setModalType] = React.useState("login")

  const [modalOpen, setModalOpen] = React.useState(false)
  const openModal = modalType => {
    setModalOpen(true)
    setModalType(modalType)
    setMobileNavOpen(false)
  }
  const close = () => setModalOpen(false)

  const openLogin = () => openModal("login")
  const openSignup = () => openModal("signup")

  return (
    <div
      sx={{
        position: "fixed",
        top: 0,
        width: "100%",
        height: ["navMobile", , , "navDesktop"],
        bg: "white",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        color: "blue",
        zIndex: "nav",
        boxShadow: [
          `0 3px rgba(55,81,255,0.15)`,
          ,
          ,
          scrolled ? `0 3px rgba(55,81,255,0.15)` : "none",
        ],
        px: 3,
        transition: `0.2s ease-in-out all`,
      }}
      {...props}
    >
      <NavbarLogo />

      <Dialog
        open={modalOpen}
        close={close}
        aria-label="Login and Signup dialog"
      >
        <LoginForm type={modalType} typeSwitcher={setModalType} />
      </Dialog>

      <div sx={{ display: "flex" }}>
        <div
          sx={theme => ({
            textTransform: "uppercase",
            display: ["none", , , "flex"],
            justifyContent: "space-between",
            alignItems: "center",
            zIndex: theme.zIndices.nav - 1,
            transition: "0.5s ease-in-out left",
          })}
        >
          {links.map((link, i) => (
            <NavLink key={i} onClick={() => handleScrollTo(link.ref)}>
              {link.name}
            </NavLink>
          ))}
          <NavLink
            sx={{
              color: "teal",
              borderLeft: "2px solid",
              borderLeftColor: "teal",
            }}
            onClick={openLogin}
          >
            {"LOGIN"}
          </NavLink>
          <MarketButton onClick={openSignup} />
        </div>
        <ButtonClean
          onClick={() => setMobileNavOpen(!mobileNavOpen)}
          sx={{
            "& > div": {
              width: 28,
              height: 3,
              bg: "blue",
              display: `inline-block`,
            },
            display: ["flex", , , "none"],
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-between",
            height: 24,
          }}
        >
          <div />
          <div />
          <div />
        </ButtonClean>
        <div
          ref={mobileMenuRef}
          sx={{
            left: "100%",
            position: "fixed",
            bg: "white",
            minWidth: "75%",
            display: ["flex", , , "none"],
            flexDirection: "column",
            transition: `0.3s ease-in-out transform`,
            transform: `translateX(${mobileNavOpen ? -100 : 2}%)`,
            top: 0,
            boxShadow: `-3px 0 rgba(55,81,255,0.15)`,
            px: 3,
            height: "100vh",
            overflow: "scroll",
          }}
        >
          <div
            sx={{
              display: "flex",
              alignItems: "center",
              height: "navMobile",
              flexShrink: 0,
            }}
          >
            <ButtonClean
              onClick={() => setMobileNavOpen(!mobileNavOpen)}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                height: 24,
                mr: 4,

                "& > div": {
                  width: 28,
                  height: 3,
                  bg: "blue",
                  transformOrigin: `0% 50%`,
                  display: `inline-block`,
                },
              }}
            >
              {/* <div sx={{ transform: `rotate(45deg)` }} /> */}
              {/* <div sx={{ transform: `rotate(-45deg)` }} /> */}
              <MdClose sx={{ fontSize: "2em" }} />
            </ButtonClean>
            <P1 sx={{ mb: 0 }}>INTROMATH</P1>
          </div>
          {links.map((link, i) => (
            <ButtonClean
              sx={{ my: 3, textAlign: "left" }}
              key={i}
              onClick={() => handleScrollTo(link.ref)}
            >
              <P2 sx={{ mb: 0, textTransform: "uppercase" }}>{link.name}</P2>
            </ButtonClean>
          ))}
          <SocialLinks
            sx={{
              mt: "auto",
              mb: 3,
              pb: 3,
              borderBottom: "2px solid",
              borderBottomColor: "teal",
            }}
          />
          <Link
            onClick={openLogin}
            sx={{
              color: "teal",
              textAlign: "center",
              mb: 3,
              "&, &:hover, &:focus": {
                textDecoration: "none",
              },
            }}
          >
            <P2
              sx={{
                mb: `0`,
                position: "relative",
                zIndex: 3,
                fontWeight: "bold",
              }}
            >
              LOGIN
            </P2>
          </Link>
          <MarketButton sx={{ mb: 4 }} onClick={openSignup} />
        </div>
      </div>
    </div>
  )
}
