/** @jsx jsx */
import { jsx } from "theme-ui"
import { useStaticQuery, graphql } from "gatsby"
import { keyframes } from "@emotion/core"

import GatsbyImage from "gatsby-image"
import HeroShape from "./patterns/HeroShape"
import DashedLines from "./patterns/DashedLines"
import StairsLines from "./patterns/StairsLines"
import { H3, H4 } from "../components/Typography"
import {
  AdditionLarge,
  AdditionSmall,
  Division,
  Multiply,
  Subtraction,
} from "./HeroFloatingOperators"

const ImgContainer = ({ ...props }) => (
  <div
    sx={{
      width: "100%",
      height: ["100%", , , 900],
      position: "absolute",
      top: ["5%", , , 0],
      left: 0,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      pt: 5,
    }}
    {...props}
  />
)

const oscilateY = keyframes`
  from {
    transform: translateY(0%);
  }
  to {
    transform: translateY(calc(10% + 20px))
  }
`

const yAnim = ({ seconds, delay = -20 }) => ({
  animation: `${oscilateY} ${seconds}s ease-in-out infinite alternate`,
  animationDelay: delay,
})

const OperatorContainer = ({ ...props }) => {
  return (
    <div
      sx={{
        position: "absolute",
        opacity: [0.1, , , 1],
        transform: [`scale(0.35)`, , , `scale(1)`],
      }}
      {...props}
    />
  )
}

const Hero = ({ ...props }) => {
  const { bg, girl } = useStaticQuery(graphql`
    query {
      girl: file(relativePath: { eq: "Hero/floating_girl.png" }) {
        childImageSharp {
          fluid(maxWidth: 412, quality: 85) {
            ...GatsbyImageSharpFluid
            presentationWidth
          }
        }
      }
      bg: file(relativePath: { eq: "Hero/bg.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 85, toFormat: JPG) {
            ...GatsbyImageSharpFluid
            presentationWidth
          }
        }
      }
    }
  `)

  return (
    <div
      sx={{ position: "relative", width: "100%", height: ["150vw", , , 1123] }}
      {...props}
    >
      <div
        sx={{
          maxWidth: "100%",
          position: "absolute",
          left: "50%",
          transform: "translateX(-50%)",
          width: "100%",
          overflow: "hidden",
        }}
      >
        <img
          src={bg.childImageSharp.fluid.src}
          sx={{
            position: "relative",
            left: ["-10%", , , 0],
            height: ["150vw", , , 1123],
            width: [`120%`, , , "100%"],
          }}
          alt=""
        />
      </div>
      <StairsLines
        sx={{
          position: "absolute",
          left: ["80%", , "75%"],
          bottom: 0,
          transform: `translateY(25%)`,
          width: ["12%", , , 112],
          height: "auto",
        }}
      />
      <DashedLines
        sx={{
          position: "absolute",
          left: "-5%",
          top: ["50%", , "30%"],
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: ["30%", , "20%"],
        }}
      />

      <ImgContainer sx={{ mt: [0, , , 4] }}>
        <OperatorContainer sx={{ left: "10%", top: "5%" }}>
          <AdditionLarge sx={{ ...yAnim({ seconds: 4 }) }} />
        </OperatorContainer>
        <OperatorContainer sx={{ left: "70%", top: "55%" }}>
          <AdditionSmall sx={{ ...yAnim({ seconds: 2 }) }} />
        </OperatorContainer>
        <OperatorContainer sx={{ left: ["20%", , , "25%"], top: "40%" }}>
          <Multiply sx={{ ...yAnim({ seconds: 5, delay: -43 }) }} />
        </OperatorContainer>
        <OperatorContainer sx={{ left: "18%", top: "60%" }}>
          <Division sx={{ ...yAnim({ seconds: 3 }) }} />
        </OperatorContainer>
        <OperatorContainer sx={{ left: "80%", top: "15%" }}>
          <Subtraction sx={{ ...yAnim({ seconds: 4 }) }} />
        </OperatorContainer>
      </ImgContainer>

      <ImgContainer>
        <HeroShape
          sx={{
            maxWidth: ["90%"],
            width: ["90%", , , "auto"],
            height: "auto",
            transform: [
              "translateY(-50%)",
              "translateY(-70%)",
              ,
              "translateY(-10%)",
            ],
          }}
        />
      </ImgContainer>
      <ImgContainer
        sx={{
          flexDirection: "column",
          color: "white",
          top: "-10%",
          textAlign: "center",
        }}
      >
        <H3
          sx={{ mb: 0, fontSize: [36, 42, , , 64] }}
        >{`PREPARING STUDENTS`}</H3>
        <H4>{`for success in mathematics`}</H4>
        <div
          sx={{
            maxWidth: ["55%", , , girl.childImageSharp.fluid.presentationWidth],
            width: "100%",
            position: "relative",
          }}
        >
          <GatsbyImage fluid={girl.childImageSharp.fluid} />
        </div>
      </ImgContainer>
    </div>
  )
}

export default Hero
