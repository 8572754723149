/** @jsx jsx */
import { jsx } from "theme-ui"
import { useStaticQuery, graphql } from "gatsby"
import GatsbyImage from "gatsby-image"

export default function NavbarLogo({ ...props }) {
  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "Logos/EMM_Nav.png" }) {
        childImageSharp {
          fluid(maxWidth: 373, quality: 85) {
            ...GatsbyImageSharpFluid
            presentationWidth
          }
        }
      }
    }
  `)

  return (
    <div
      sx={{
        width: "100%",
        maxWidth: data.logo.childImageSharp.fluid.presentationWidth / 2,
      }}
      {...props}
    >
      <GatsbyImage fluid={data.logo.childImageSharp.fluid} />
    </div>
  )
}
