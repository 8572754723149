import React from "react"
/** @jsx jsx */
import { jsx } from "theme-ui"

import { useStaticQuery, graphql } from "gatsby"
import GatsbyImage from "gatsby-image"
import Link from "../components/Link"
import { FiChevronLeft } from "react-icons/fi"
import Slider from "react-slick"
import "../../node_modules/slick-carousel/slick/slick.css"

const Arrow = ({ ...props }) => (
  <button
    sx={{
      fontSize: `3em`,
      color: "black",
      top: "50%",
      bg: "transparent",
      position: "absolute",
      p: 0,
      border: "none",
      cursor: "pointer",
      "&:hover, &:focus": { color: "black" },
    }}
    {...props}
  >
    <FiChevronLeft sx={{ display: "block" }} />
  </button>
)

const Partners = React.forwardRef((props, ref) => {
  const data = useStaticQuery(graphql`
    fragment PartnerImg on File {
      childImageSharp {
        fluid(maxWidth: 150, quality: 85) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    query {
      image1: file(relativePath: { eq: "partners/small/1.png" }) {
        ...PartnerImg
      }
      image2: file(relativePath: { eq: "partners/small/2.png" }) {
        ...PartnerImg
      }
      image3: file(relativePath: { eq: "partners/small/3.png" }) {
        ...PartnerImg
      }
      image4: file(relativePath: { eq: "partners/small/4.png" }) {
        ...PartnerImg
      }
      image5: file(relativePath: { eq: "partners/small/5.png" }) {
        ...PartnerImg
      }
      image6: file(relativePath: { eq: "partners/small/6.png" }) {
        ...PartnerImg
      }
      image7: file(relativePath: { eq: "partners/small/7.png" }) {
        ...PartnerImg
      }
      image8: file(relativePath: { eq: "partners/small/8.png" }) {
        ...PartnerImg
      }
      image9: file(relativePath: { eq: "partners/small/9.png" }) {
        ...PartnerImg
      }
      image10: file(relativePath: { eq: "partners/small/10.png" }) {
        ...PartnerImg
      }
      image11: file(relativePath: { eq: "partners/small/11.png" }) {
        ...PartnerImg
      }
      image12: file(relativePath: { eq: "partners/small/12.png" }) {
        ...PartnerImg
      }
      image13: file(relativePath: { eq: "partners/small/13.png" }) {
        ...PartnerImg
      }
      image14: file(relativePath: { eq: "partners/small/14.png" }) {
        ...PartnerImg
      }
      image15: file(relativePath: { eq: "partners/small/15.png" }) {
        ...PartnerImg
      }
      image16: file(relativePath: { eq: "partners/small/16.png" }) {
        ...PartnerImg
      }
      image17: file(relativePath: { eq: "partners/small/17.png" }) {
        ...PartnerImg
      }
      image18: file(relativePath: { eq: "partners/small/18.png" }) {
        ...PartnerImg
      }
      image19: file(relativePath: { eq: "partners/small/19.png" }) {
        ...PartnerImg
      }
      image20: file(relativePath: { eq: "partners/small/20.png" }) {
        ...PartnerImg
      }
      image21: file(relativePath: { eq: "partners/small/21.png" }) {
        ...PartnerImg
      }
      image22: file(relativePath: { eq: "partners/small/22.png" }) {
        ...PartnerImg
      }
      image23: file(relativePath: { eq: "partners/small/23.png" }) {
        ...PartnerImg
      }
      image24: file(relativePath: { eq: "partners/small/24.png" }) {
        ...PartnerImg
      }
      image25: file(relativePath: { eq: "partners/small/25.png" }) {
        ...PartnerImg
      }
      image26: file(relativePath: { eq: "partners/small/26.png" }) {
        ...PartnerImg
      }
      image27: file(relativePath: { eq: "partners/small/27.png" }) {
        ...PartnerImg
      }
      image28: file(relativePath: { eq: "partners/small/28.png" }) {
        ...PartnerImg
      }
      image29: file(relativePath: { eq: "partners/small/29.png" }) {
        ...PartnerImg
      }
      image30: file(relativePath: { eq: "partners/small/30.png" }) {
        ...PartnerImg
      }
      image31: file(relativePath: { eq: "partners/small/31.png" }) {
        ...PartnerImg
      }
      image32: file(relativePath: { eq: "partners/small/32.png" }) {
        ...PartnerImg
      }
      image33: file(relativePath: { eq: "partners/small/33.png" }) {
        ...PartnerImg
      }
      image34: file(relativePath: { eq: "partners/small/34.png" }) {
        ...PartnerImg
      }
      image35: file(relativePath: { eq: "partners/small/35.png" }) {
        ...PartnerImg
      }
      image36: file(relativePath: { eq: "partners/small/36.png" }) {
        ...PartnerImg
      }
      image37: file(relativePath: { eq: "partners/small/37.png" }) {
        ...PartnerImg
      }
      image38: file(relativePath: { eq: "partners/small/38.png" }) {
        ...PartnerImg
      }
      image91: file(relativePath: { eq: "partners/small/91.png" }) {
        ...PartnerImg
      }
      cuyahoga: file(relativePath: { eq: "partners/small/cuyahoga.png" }) {
        ...PartnerImg
      }
      nasbtt: file(relativePath: { eq: "partners/small/nasbtt.jpg" }) {
        ...PartnerImg
      }
      nipissing_university: file(
        relativePath: { eq: "partners/small/nipissing_university.png" }
      ) {
        ...PartnerImg
      }
      UODlogo: file(relativePath: { eq: "partners/small/UODlogo.png" }) {
        ...PartnerImg
      }
    }
  `)

  const sliderRef = React.useRef(null)
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          dots: false,
        },
      },
      {
        breakpoint: 440,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
        },
      },
    ],
  }

  return (
    <div sx={{ position: "relative" }} ref={ref} {...props}>
      <div
        sx={{
          "& .slick-track": {
            display: "flex",

            ".slick-slide": {
              height: "auto",

              "> div": {
                height: "100%",
                display: "flex",
                alignItems: "center",
              },
            },
          },
        }}
      >
        <Slider ref={sliderRef} {...settings}>
          {[
            { link: "https://www.derby.ac.uk", image: data.UODlogo },
            { link: "https://www.nasbtt.org.uk/", image: data.nasbtt },
            { link: "//brocku.ca/", image: data.image28 },
            {
              link: "https://www.nipissingu.ca/",
              image: data.nipissing_university,
            },
            { link: "//www.mohawkcollege.ca/", image: data.image24 },
            { link: "http://www.cambriancollege.ca/", image: data.image27 },
            { link: "//www.fanshawec.ca/", image: data.image17 },
            { link: "http://www.collegeboreal.ca/", image: data.image5 },
            { link: "//www.algonquincollege.com/", image: data.image1 },
            { link: "//www.sheridancollege.ca/", image: data.image18 },
            { link: "https://www.tri-c.edu/", image: data.cuyahoga },
            // { link: "//www.senecacollege.ca/", image: data.image22 },
            // { link: "//www.humber.ca/", image: data.image23 },
            // { link: "//www.conestogac.on.ca/", image: data.image7 },
            // { link: "//www.lambton.on.ca/", image: data.image10 },
            // { link: "http://www.niagaracollege.ca/", image: data.image16 },
            // { link: "http://bowvalleycollege.ca/", image: data.image3 },
            // { link: "http://www.sait.ca/", image: data.image20 },
            // { link: "//www.hollandcollege.com/", image: data.image25 },
            // {
            //   link:
            //     "https://portal.education.lu/qualitescolaire/Grands-dossiers/ArtMID/3027/ArticleID/348840/Ma",
            //   image: data.image38,
            // },
            // { link: "//flemingcollege.ca/", image: data.image8 },
            // { link: "//www.northernlakescollege.ca/", image: data.image13 },
            // { link: "http://www.loyalistcollege.com/", image: data.image19 },
            // { link: "https://www.gprc.ab.ca/", image: data.image26 },
            // { link: "//www.centennialcollege.ca/", image: data.image4 },
            // { link: "//www.ucn.ca/", image: data.image12 },
            // { link: "http://www.ontariolearn.com/", image: data.image15 },
            // { link: "//www.norquest.ca/", image: data.image14 },
            // { link: "http://www.auroracollege.nt.ca/", image: data.image2 },
            // { link: "http://www.cnc.bc.ca/", image: data.image6 },

            // { link: "http://www.luc.edu/", image: data.image29 },
            // { link: "//www.wlu.ca/", image: data.image30 },
            // { link: "//www.ccbcmd.edu/", image: data.image31 },
            // { link: "http://www.tri-c.edu/", image: data.image32 },
            // { link: "http://www.edu.gov.on.ca/eng/", image: data.image35 },
            // { link: "http://www.tcu.gov.on.ca/eng/", image: data.image36 },
            // { link: "//www.gouvernement.lu/", image: data.image34 },
            // { link: "http://www.education.gouv.fr/", image: data.image33 },
          ].map((partner, index) => (
            <div key={index} sx={{ textAlign: "center", outline: "none" }}>
              <Link to={partner.link} aria-label={`partner logo ${index}`}>
                <div sx={{ maxWidth: 150, mx: "auto" }}>
                  <GatsbyImage fluid={partner.image.childImageSharp.fluid} />
                </div>
              </Link>
            </div>
          ))}
        </Slider>
      </div>

      <Arrow
        onClick={() => sliderRef.current.slickPrev()}
        title="Previous slide set"
        sx={{ transform: ["translateY(-50%)", "translate(-100%,-50%)"] }}
      />
      <Arrow
        onClick={() => sliderRef.current.slickNext()}
        title="Next slide set"
        sx={{
          transform: [
            `translateY(-50%) rotate(180deg)`,
            "translate(100%,-50%) rotate(180deg)",
          ],
          right: 0,
        }}
      />
    </div>
  )
})

export default Partners
