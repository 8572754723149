import React from "react"
/** @jsx jsx */
import { jsx } from "theme-ui"

import { useStaticQuery, graphql } from "gatsby"
import GatsbyImage from "gatsby-image"
import { Container, Row, Col } from "../components/Grid"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import { H2, H4, H5, P1, P2 } from "../components/Typography"
import Navbar from "../components/Navbar"
import PartnersCarousel from "../components/Partners"
import TextArrows from "../images/HowItWorks/Arrows.svg"
import Certification from "../components/Certification"
import Hero from "../components/Hero"
import DashedLines from "../components/patterns/DashedLines"

const Section = ({ as = "section", ...props }) => {
  const El = as
  return (
    <El
      sx={{ mb: [5, , 6, , 7], position: "relative", mx: "-8px", px: "8px" }}
      {...props}
    />
  )
}

const HowItWorksItem = ({ image, title, description, index, ...props }) => {
  const even = index % 2 === 0

  return (
    <Row sx={{ alignItems: "center", mb: [5, , , , 156] }} {...props}>
      <Col
        lg={2 / 3}
        sx={{ order: [1, , , even ? 1 : 2], px: 0, mb: [4, , , 0] }}
      >
        <GatsbyImage fluid={image.childImageSharp.fluid} />
      </Col>
      <Col
        lg={1 / 3}
        sx={{
          order: [2, , , even ? 2 : 1],
          textAlign: "left",
        }}
      >
        <div sx={{ display: "inline-flex", flexDirection: "column" }}>
          <img src={TextArrows} sx={{ width: 98, ml: 1, mb: 2 }} alt="" />
          <H4 sx={{ mb: 3 }}>{title}</H4>
        </div>
        <P1 dangerouslySetInnerHTML={{ __html: description }} />
      </Col>
    </Row>
  )
}

const SchrollAnchor = React.forwardRef((props, ref) => (
  <div
    {...props}
    ref={ref}
    sx={theme => ({
      position: "relative",
      top: [-theme.sizes.navMobile, , , , -theme.sizes.navDesktop],
    })}
  />
))

const DashedLinesSmall = ({ ...props }) => (
  <DashedLines
    small
    sx={{
      position: "absolute",
      display: ["none", , , "block"],
    }}
    {...props}
  />
)

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      HiW1: file(relativePath: { eq: "HowItWorks/1.png" }) {
        childImageSharp {
          fluid(maxWidth: 793, quality: 85) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      HiW2: file(relativePath: { eq: "HowItWorks/2.png" }) {
        childImageSharp {
          fluid(maxWidth: 793, quality: 85) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      HiW3: file(relativePath: { eq: "HowItWorks/3.png" }) {
        childImageSharp {
          fluid(maxWidth: 793, quality: 85) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      featuresImages: allFile(
        filter: { relativeDirectory: { eq: "Features" } }
        sort: { fields: name }
      ) {
        edges {
          node {
            childImageSharp {
              fluid(maxWidth: 285, quality: 85) {
                ...GatsbyImageSharpFluid
                presentationHeight
              }
            }
          }
        }
      }
    }
  `)

  const howItWorksRef = React.useRef(null)
  const featuresRef = React.useRef(null)
  const partnersRef = React.useRef(null)

  return (
    <Layout>
      <SEO title="Elevate My Maths" />

      <Navbar
        links={[
          { name: "How It Works", ref: howItWorksRef },
          { name: "Features", ref: featuresRef },
          { name: "Partners", ref: partnersRef },
        ]}
      />

      <div>
        <Hero
          sx={t => ({
            mt: [t.sizes.navDesktop],
            mb: 6,
          })}
        />
        <Section sx={{ overflow: "hidden" }}>
          <DashedLinesSmall
            sx={{
              right: 0,
              transform: `translateX(50%)`,
              top: `22%`,
            }}
          />
          <SchrollAnchor ref={howItWorksRef} />
          <Container>
            <H2 sx={{ textAlign: "center" }}>HOW IT WORKS</H2>
            {[
              {
                image: data.HiW1,
                title: "IDENTIFY AREAS FOR IMPROVEMENT",
                description:
                  "Students complete a carefully designed diagnostic assessment that identifies their strengths and areas for improvement in key mathematics topics.",
              },
              {
                image: data.HiW2,
                title: "UPGRADE SKILLS",
                description:
                  "In the topics requiring improvement, students upgrade their skills by completing modules of interactive lessons that provide continuous feedback and support throughout the <b>assessment for learning</b> experience.",
              },
              {
                image: data.HiW3,
                title: "DEMONSTRATE PROFICIENCY",
                description:
                  "A summative assessment enables students to demonstrate their proficiency and to move forward with confidence to further study.",
              },
            ].map((item, index) => (
              <HowItWorksItem
                image={item.image}
                title={item.title}
                description={item.description}
                key={index}
                index={index}
              />
            ))}
          </Container>
        </Section>

        <div sx={{ position: "relative" }}>
          <DashedLinesSmall
            sx={{
              left: 0,
              transform: `translate(-50%, -45%)`,
            }}
          />
          <Certification sx={{ mb: 6 }} />
        </div>

        <Section sx={{ overflow: "hidden", mb: [5, , 6, , 6] }}>
          <DashedLinesSmall
            stroke="teal"
            sx={{
              left: 0,
              transform: `translateX(-50%)`,
              top: `40%`,
            }}
          />
          <Container sx={{ px: 3 }}>
            <SchrollAnchor ref={featuresRef} />
            <H2 sx={{ textAlign: "center" }}>KEY FEATURES</H2>
            <Row sx={{ mx: -3 }}>
              {[
                {
                  title: "Test Designs",
                  description:
                    "Research-backed test designs are available (and can be customized) for maths courses in business, technology, health sciences, nursing, trades, apprenticeship, and teacher education programmes.",
                },
                {
                  title: "Upgrading Modules",
                  description:
                    "Interactive and voice-enabled upgrading (remedial) modules break down concepts into micro-steps for students to visualize, conceptualize, and engage with real world simulations in mathematics.",
                },
                {
                  title: "Dashboards",
                  description:
                    "Intuitive and actionable visualizations provide educators and students with interactive tools to monitor their progress and performance, supporting the learning and assessment process.",
                },
                {
                  title: "Administration",
                  description:
                    "Powerful admin tools provide educators and administrators with the ability to customize the learning and assessment experience to support their teaching practices and curriculum requirements.",
                },
              ].map((feature, featIndex) => (
                <Col
                  sm={1}
                  md={1 / 2}
                  xl={1 / 4}
                  key={featIndex}
                  sx={{ textAlign: "center", mb: 5, px: 3 }}
                >
                  <div
                    sx={{ mx: "auto", mb: 4, maxWidth: ["80%", , , "auto"] }}
                  >
                    <GatsbyImage
                      fluid={
                        data.featuresImages.edges[featIndex].node
                          .childImageSharp.fluid
                      }
                    />
                  </div>
                  <H5>{feature.title}</H5>
                  <P2 sx={{ mb: 0 }}>{feature.description}</P2>
                </Col>
              ))}
            </Row>
          </Container>
        </Section>

        <Section>
          <Container sx={{ mb: 6 }}>
            <SchrollAnchor ref={partnersRef} />
            <Section>
              <H2 sx={{ textAlign: "center", pt: 3 }}>PARTNERS</H2>
              <PartnersCarousel />
            </Section>
          </Container>
        </Section>
      </div>
    </Layout>
  )
}

export default IndexPage
